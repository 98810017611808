// @ts-ignore
import { PMT, IPMT } from '@formulajs/formulajs'
import { CaseConfiguration } from './financialCalculator'

export type SelfManageCalculatorInputs = {
  propertyValue: number
  monthlyRent: number
  remainingMortgageAmount: number
  mortgageYearsRemaining: number
  mortgageInterestRate: number

  vacancyRate: number
  propertyManagementFees: number
  annualTurnCost: number
  monthlyMaintenanceCost: number
  insurance: number
  leasingCommissions: number
  propertyTaxes: number
  capitalReserve: number
  monthlyHoaFees: number
  monthlyUtilities: number

  hpa: number
  rentGrowth: number

  targetContributionDate?: Date
  yearsToProject?: number
}

export type AnnualSelfManageModelAggregates = {
  [key: string]: number[]
  year: number[]
  baseRentalRevenue: number[]
  vacancy: number[]
  turnCost: number[]
  effectiveGrossRent: number[]
  repairsAndMaintenance: number[]
  propertyManagementFee: number[]
  propertyTaxes: number[]
  insurance: number[]
  netOperatingIncome: number[]
  capitalReserve: number[]
  hoaFees: number[]
  utilities: number[]
  leasingCommission: number[]
  cashFlowAfterCapital: number[]
  mortgagePayment: number[]
  mortgageInterest: number[]
  mortgagePrincipal: number[]
  mortgageStartingBalance: number[]
  mortgageEndingBalance: number[]
  netCashFlow: number[]

  ownHomeBeginningEquity: number[]
  ownHomeAppreciation: number[]
  ownHomeEndingEquity: number[]
}

type AnnualSelfManageModelAggregate = {
  [key: string]: number
  year: number
  baseRentalRevenue: number
  vacancy: number
  turnCost: number
  effectiveGrossRent: number
  repairsAndMaintenance: number
  propertyManagementFee: number
  propertyTaxes: number
  insurance: number
  netOperatingIncome: number
  capitalReserve: number
  hoaFees: number
  utilities: number
  leasingCommission: number
  cashFlowAfterCapital: number
  mortgagePayment: number
  mortgageInterest: number
  mortgagePrincipal: number
  mortgageStartingBalance: number
  mortgageEndingBalance: number
  netCashFlow: number

  ownHomeBeginningEquity: number
  ownHomeAppreciation: number
  ownHomeEndingEquity: number
}

// Calculator based on https://docs.google.com/spreadsheets/d/1sVeHgote751rXO1LzJ0nnqXwZuWCXeqh/edit#gid=1264933710
export const calculateSelfManageFinancialModel = (
  inputs: SelfManageCalculatorInputs
) => {
  const {
    propertyValue,
    monthlyRent,
    remainingMortgageAmount,
    mortgageYearsRemaining,
    mortgageInterestRate,
    vacancyRate,
    propertyManagementFees,
    annualTurnCost,
    monthlyMaintenanceCost,
    insurance,
    leasingCommissions,
    propertyTaxes,
    capitalReserve,
    monthlyHoaFees,
    monthlyUtilities,
    rentGrowth,
    hpa,
    targetContributionDate,
    yearsToProject,
  } = inputs

  const propertyTaxRate = propertyTaxes / propertyValue
  const insuranceRate = insurance / propertyValue
  const leasingCommissionsRate = leasingCommissions / (monthlyRent || 1)

  const contributionDate = targetContributionDate || new Date()
  if (!targetContributionDate) {
    contributionDate.setDate(contributionDate.getDate() + 14)
    contributionDate.setMonth(contributionDate.getMonth() + 1)
    contributionDate.setDate(1)
  }

  const nextMonth = contributionDate.getMonth()
  const thisYear = contributionDate.getFullYear()

  // Stores the data for each category as an array per year
  const aggregates: AnnualSelfManageModelAggregates = {
    year: [],
    baseRentalRevenue: [],
    vacancy: [],
    turnCost: [],
    effectiveGrossRent: [],
    repairsAndMaintenance: [],
    propertyManagementFee: [],
    propertyTaxes: [],
    insurance: [],
    netOperatingIncome: [],
    capitalReserve: [],
    hoaFees: [],
    utilities: [],
    leasingCommission: [],
    cashFlowAfterCapital: [],
    mortgagePayment: [],
    mortgageInterest: [],
    mortgagePrincipal: [],
    mortgageStartingBalance: [],
    mortgageEndingBalance: [],
    netCashFlow: [],

    ownHomeBeginningEquity: [],
    ownHomeAppreciation: [],
    ownHomeEndingEquity: [],
  }

  let outstandingMortgage = remainingMortgageAmount

  let currentPropertyValue = propertyValue
  let ownEquityBalance = propertyValue - outstandingMortgage

  for (let year = 0; year < (yearsToProject || 5); year += 1) {
    let yearValue = year
    if (year === 0 && nextMonth !== 0) {
      yearValue = nextMonth / 12
    }
    const annualAggregate: AnnualSelfManageModelAggregate = {
      year: thisYear + yearValue,
      baseRentalRevenue: 0,
      vacancy: 0,
      turnCost: 0,
      effectiveGrossRent: 0,
      repairsAndMaintenance: 0,
      propertyManagementFee: 0,
      propertyTaxes: 0,
      insurance: 0,
      netOperatingIncome: 0,
      capitalReserve: 0,
      hoaFees: 0,
      utilities: 0,
      leasingCommission: 0,
      cashFlowAfterCapital: 0,
      mortgagePayment: 0,
      mortgageInterest: 0,
      mortgagePrincipal: 0,
      mortgageStartingBalance: 0,
      mortgageEndingBalance: 0,
      netCashFlow: 0,

      ownHomeBeginningEquity: 0,
      ownHomeAppreciation: 0,
      ownHomeEndingEquity: 0,
    }

    const firstMonth = year === 0 ? nextMonth : 0

    annualAggregate.ownHomeBeginningEquity = ownEquityBalance

    // Compute the property taxes based on the property values at the beginning of the year and prorate accordingly
    annualAggregate.propertyTaxes =
      (propertyTaxRate * currentPropertyValue * (12 - firstMonth)) / 12
    annualAggregate.insurance =
      (insuranceRate * currentPropertyValue * (12 - firstMonth)) / 12
    annualAggregate.leasingCommission =
      leasingCommissionsRate * monthlyRent * (1 + rentGrowth) ** year
    annualAggregate.hoaFees = (monthlyHoaFees || 0) * (12 - firstMonth)
    annualAggregate.utilities = (monthlyUtilities || 0) * (12 - firstMonth)

    for (let month = firstMonth; month < 12; month += 1) {
      // Effective gross rent
      const monthRentalRevenue = monthlyRent * (1 + rentGrowth) ** year
      const monthVacancy = vacancyRate * monthRentalRevenue
      const monthTurnCost = annualTurnCost / 12
      const monthEffectiveGrossRent =
        monthRentalRevenue - monthVacancy - monthTurnCost

      annualAggregate.baseRentalRevenue += monthRentalRevenue
      annualAggregate.vacancy += monthVacancy
      annualAggregate.turnCost += monthTurnCost

      // Net Operating Income
      const monthMaintenance = monthlyMaintenanceCost
      const monthPropManagement =
        propertyManagementFees * monthEffectiveGrossRent

      annualAggregate.repairsAndMaintenance += monthMaintenance
      annualAggregate.propertyManagementFee += monthPropManagement

      const monthCapitalReserve = capitalReserve

      annualAggregate.capitalReserve += monthCapitalReserve

      const beginningBalance = outstandingMortgage
      const monthPayment =
        PMT(
          mortgageInterestRate / 12,
          mortgageYearsRemaining * 12,
          -remainingMortgageAmount
        ) || 0
      const monthMortgageInterest =
        IPMT(
          mortgageInterestRate / 12,
          year * 12 + month + 1,
          mortgageYearsRemaining * 12,
          -remainingMortgageAmount
        ) || 0
      const monthPrincipal = monthPayment - monthMortgageInterest

      const endingBalance = beginningBalance - monthPrincipal
      outstandingMortgage = endingBalance

      annualAggregate.mortgageStartingBalance = beginningBalance
      annualAggregate.mortgagePayment += monthPayment
      annualAggregate.mortgagePrincipal += monthPrincipal
      annualAggregate.mortgageInterest += monthMortgageInterest
      annualAggregate.mortgageEndingBalance = endingBalance

      const monthAppreciation = (currentPropertyValue * hpa) / 12
      currentPropertyValue += monthAppreciation

      // this is wrong? your equity is not growing directly with HPA
      // your equity is a static % of the house and as the house grows with hpa
      // you actually get the entire exposure
      const ownEquityAppreciation = (ownEquityBalance * hpa) / 12
      annualAggregate.ownHomeAppreciation += ownEquityAppreciation
      ownEquityBalance += ownEquityAppreciation + monthPrincipal
    }

    annualAggregate.ownHomeEndingEquity = ownEquityBalance

    annualAggregate.effectiveGrossRent =
      annualAggregate.baseRentalRevenue -
      annualAggregate.vacancy -
      annualAggregate.turnCost
    annualAggregate.netOperatingIncome =
      annualAggregate.effectiveGrossRent -
      annualAggregate.repairsAndMaintenance -
      annualAggregate.propertyManagementFee -
      annualAggregate.propertyTaxes -
      annualAggregate.insurance -
      annualAggregate.hoaFees -
      annualAggregate.utilities
    annualAggregate.cashFlowAfterCapital =
      annualAggregate.netOperatingIncome -
      annualAggregate.capitalReserve -
      annualAggregate.leasingCommission
    annualAggregate.netCashFlow =
      annualAggregate.cashFlowAfterCapital -
      annualAggregate.mortgageInterest -
      annualAggregate.mortgagePrincipal

    Object.keys(annualAggregate).forEach((key: string) => {
      aggregates[key].push(annualAggregate[key])
    })
  }

  return aggregates
}

export type FlockCalculatorInputs = {
  propertyValue: number
  equityTakeout: number
  rentReduction?: number
  daysInRemodelDeduction?: number
  onboardingFee: number
  closingAndLegalFee: number
  capexCosts: number
  remainingMortgageAmount: number
  hpa: number
  flockDistributionRate: number
  reinvestDistributions: boolean
  fundEquityPercentTarget: number
  targetContributionDate?: Date
  yearsToProject?: number
  hpaCompounding: CompoundingGrowth

  overmoonDistributionScaling?: boolean
}

export type AnnualFlockModelAggregates = {
  [key: string]: number[]
  year: number[]
  flockCashFlow: number[]
  flockBeginningEquity: number[]
  flockAppreciation: number[]
  flockEndingEquity: number[]
}

type AnnualFlockModelAggregate = {
  [key: string]: number
  year: number
  flockCashFlow: number
  flockBeginningEquity: number
  flockAppreciation: number
  flockEndingEquity: number
}

export enum CompoundingGrowth {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annually',
}

export const calculateFlockFinancialModel = (inputs: FlockCalculatorInputs) => {
  const {
    propertyValue,
    equityTakeout,
    rentReduction,
    daysInRemodelDeduction,
    onboardingFee,
    closingAndLegalFee,
    capexCosts,
    remainingMortgageAmount,
    flockDistributionRate,
    reinvestDistributions,
    hpa,
    targetContributionDate,
    yearsToProject,
    fundEquityPercentTarget,
    hpaCompounding,
    overmoonDistributionScaling,
  } = inputs

  let distributionRate = flockDistributionRate
  const contributionDate = targetContributionDate || new Date()
  if (!targetContributionDate) {
    contributionDate.setDate(contributionDate.getDate() + 14)
    contributionDate.setMonth(contributionDate.getMonth() + 1)
    contributionDate.setDate(1)
  }

  const nextMonth = contributionDate.getMonth()
  const thisYear = contributionDate.getFullYear()
  // Stores the data for each category as an array per year
  const aggregates: AnnualFlockModelAggregates = {
    year: [],
    flockCashFlow: [],
    flockBeginningEquity: [],
    flockAppreciation: [],
    flockEndingEquity: [],
  }

  let flockEquityBalance =
    propertyValue -
    remainingMortgageAmount -
    onboardingFee * propertyValue -
    equityTakeout -
    (rentReduction || 0) -
    (daysInRemodelDeduction || 0) -
    capexCosts -
    closingAndLegalFee * propertyValue

  let applyProrate = true
  for (let year = 0; year < (yearsToProject || 5); year += 1) {
    const yearValue = year
    const annualAggregate: AnnualFlockModelAggregate = {
      year: thisYear + yearValue,
      flockBeginningEquity: 0,
      flockAppreciation: 0,
      flockEndingEquity: 0,
      flockCashFlow: 0,
    }

    if (overmoonDistributionScaling) {
      if (year === 0) {
        distributionRate = 0
      } else if (year === 1) {
        distributionRate = 0.025
      } else {
        distributionRate = 0.04
      }
    }

    const firstMonth = year === 0 ? nextMonth : 0

    annualAggregate.flockBeginningEquity = flockEquityBalance
    // Compute the property taxes based on the property values at the beginning of the year.

    for (let month = firstMonth; month < 12; month += 1) {
      const isQuarter = (month + 1) % 3 === 0

      let monthFlockCashFlow = 0
      let monthFlockAppreciation = 0
      let quarterlyFlockAppreciation = 0
      if (hpaCompounding === CompoundingGrowth.MONTHLY) {
        monthFlockAppreciation =
          (flockEquityBalance * hpa * (1 / fundEquityPercentTarget)) / 12
        flockEquityBalance += monthFlockAppreciation
      }

      if (isQuarter) {
        // If they join in the middle of a quarter, we need to prorate the distribution they get
        let prorateModifier = 1
        if (applyProrate) {
          if (nextMonth !== 0) {
            prorateModifier = (3 - (nextMonth % 3)) / 3
          }
          applyProrate = false
        }
        monthFlockCashFlow =
          ((prorateModifier * distributionRate) / 4) * flockEquityBalance
        if (!reinvestDistributions) {
          annualAggregate.flockCashFlow += monthFlockCashFlow
        }
        if (hpaCompounding === CompoundingGrowth.QUARTERLY) {
          quarterlyFlockAppreciation =
            (prorateModifier *
              flockEquityBalance *
              hpa *
              (1 / fundEquityPercentTarget)) /
            4
          flockEquityBalance += quarterlyFlockAppreciation
        }
      }
      const monthReinvestedDistributions = reinvestDistributions
        ? monthFlockCashFlow
        : 0
      flockEquityBalance += monthReinvestedDistributions

      // only one of these will be a value depending on the passed in compounding method
      annualAggregate.flockAppreciation +=
        monthFlockAppreciation + quarterlyFlockAppreciation
    }
    if (hpaCompounding === CompoundingGrowth.ANNUALLY) {
      const annuallyFlockAppreciation =
        (flockEquityBalance *
          hpa *
          (1 / fundEquityPercentTarget) *
          (12 - firstMonth)) /
        12
      annualAggregate.flockAppreciation += annuallyFlockAppreciation
      flockEquityBalance += annuallyFlockAppreciation
    }

    annualAggregate.flockEndingEquity = flockEquityBalance

    Object.keys(annualAggregate).forEach((key: string) => {
      aggregates[key].push(annualAggregate[key])
    })
  }

  return aggregates
}

type ProjectMultiCaseFinancialModelInputs = {
  propertyValue: number
  equityTakeout: number
  rentReduction?: number
  daysInRemodelDeduction?: number
  onboardingFee: number
  closingAndLegalFee: number
  capexCosts: number
  remainingMortgageAmount: number
  flockDistributionRate: number
  reinvestDistributions: boolean
  caseConfigurations: CaseConfiguration[]
  targetContributionDate?: Date
  yearsToProject?: number
  fundEquityPercentTarget: number
  hpaCompounding: CompoundingGrowth
}

export const projectMultiCaseFinancialModel = (
  inputs: ProjectMultiCaseFinancialModelInputs
) => {
  const { caseConfigurations, ...otherInputs } = inputs
  // For each configuration, do the computation. Then set the case config name to equal a transformed result

  const projectionsByCase: { [key: string]: any } = {}
  caseConfigurations.forEach((caseConfig) => {
    const calculatedModel = calculateFlockFinancialModel({
      hpa: caseConfig.annualHPA,
      ...otherInputs,
    })

    const caseData = []

    // Marshall the data into the correct format
    for (let i = 0; i < calculatedModel.year.length; i += 1) {
      const yearAggregate = {
        year: calculatedModel.year[i],
        initialEquity: calculatedModel.flockBeginningEquity[i],
        distribution: calculatedModel.flockCashFlow[i],
        appreciation: calculatedModel.flockAppreciation[i],
        managementFee: 0,
      }
      caseData.push(yearAggregate)
    }
    projectionsByCase[caseConfig.name] = caseData
  })

  return projectionsByCase
}
