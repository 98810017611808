import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  Tooltip,
  useTheme,
} from '@mui/material'
import React from 'react'
import AddressTextField, {
  AddressData,
} from '../AddressTextField/AddressTextField'
import { InfoIcon } from '../icons/InfoIcon'
import { RightArrowIcon } from '../icons/RightArrowIcon'
import { TrackedButton } from '../TrackedButton'
import { TrackingContextProvider } from '../TrackingContextProvider'
import { TrackingConfig } from '../useTracking'

export type InlineMessageProps = {
  text: string
  variant: 'address' | 'button'

  label: string
  size?: string
  tooltip?: string

  googleMapsApiKey?: string

  loading?: boolean

  onChange?: (addressData: AddressData | null) => void
  onSubmit?: () => void

  to?: string
  onClick?: () => void

  buttonVariant?: string
  trackingConfig?: TrackingConfig
}

const InlineMessage = (props: InlineMessageProps) => {
  const {
    text,
    variant,
    onChange,
    onSubmit,
    onClick,
    to,
    label,
    loading,
    googleMapsApiKey,
    size,
    tooltip,
    buttonVariant,
    trackingConfig,
  } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isSmall = size === 'small'

  let gap = isMobile ? '24px' : '32px'
  let fontSize = isMobile ? '1.5rem!important' : '2.25rem'
  if (isSmall) {
    gap = '10px'
    fontSize = '1.45rem!important'
  }

  return (
    <TrackingContextProvider name="inline-message">
      <Box
        display="flex"
        flexDirection={isMobile || isSmall ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isSmall ? 'left' : 'center'}
        sx={{
          p: isMobile || isSmall ? '24px' : '36px',
          borderRadius: '24px',
          backgroundColor: isSmall ? 'green1.main' : 'primary.main',
        }}
        gap={gap}
      >
        <Box maxWidth={isSmall ? undefined : '450px'}>
          <Typography
            variant="h3"
            color={isSmall ? 'primary.main' : 'secondary.main'}
            sx={
              isMobile || isSmall
                ? { fontSize, lineHeight: '2rem!important' }
                : { fontSize, lineHeight: '3rem' }
            }
          >
            {text}{' '}
            {tooltip && (
              <Tooltip
                title={tooltip}
                arrow
                placement="right"
                enterTouchDelay={0}
              >
                <Box
                  sx={{
                    width: '0.85rem',
                    height: '0.85rem',
                    display: 'inline',
                  }}
                >
                  <InfoIcon />
                </Box>
              </Tooltip>
            )}
          </Typography>
        </Box>
        {variant === 'address' ? (
          <Box
            width={isMobile || isSmall ? '100%' : '430px'}
            sx={{ mt: isMobile || isSmall ? '16px' : '0px' }}
          >
            <AddressTextField
              fullWidth
              googleMapsApiKey={googleMapsApiKey as string}
              variant="filled"
              label="Property address"
              size={isMobile || isSmall ? 'small' : 'medium'}
              placeholder={
                isMobile || isSmall
                  ? 'Get an offer now'
                  : 'Enter your property address'
              }
              ctaContent={
                loading ? (
                  <Box
                    width={isMobile || isSmall ? 'fit-content' : '48px'}
                    height={isMobile || isSmall ? '18px' : '48px'}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress size="24px" color="white" />
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={isMobile || isSmall ? 'fit-content' : '48px'}
                    height={isMobile || isSmall ? 'fit-content' : '48px'}
                  >
                    <RightArrowIcon
                      color="white"
                      width={isMobile || isSmall ? '18px' : '32px'}
                      height={isMobile || isSmall ? '18px' : '32px'}
                    />
                  </Box>
                )
              }
              onChange={onChange || (() => {})}
              onSubmit={onSubmit || (() => {})}
            />
          </Box>
        ) : (
          <TrackedButton
            // @ts-ignore
            variant={buttonVariant || (isSmall ? 'primary' : 'tertiary')}
            trackingConfig={trackingConfig}
            sx={{
              maxWidth: isMobile || isSmall ? 'unset' : '256px',
              fontSize: isSmall ? '20px' : undefined,
              padding: isSmall ? '8px 24px' : undefined,
            }}
            onClick={onClick}
            to={to}
          >
            {label}
          </TrackedButton>
        )}
      </Box>
    </TrackingContextProvider>
  )
}

export default InlineMessage

InlineMessage.defaultProps = {
  googleMapsApiKey: '',
  loading: false,
  to: undefined,
  size: 'medium',
  tooltip: '',
  trackingConfig: {},
}
