import { FormattedTextField } from '@flock/shared-ui'
import { Help } from '@mui/icons-material'
import { styled, Tooltip } from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

const StyledHelp = styled(Help)({
  fontSize: '1rem',
  cursor: 'pointer',
  marginLeft: '0.25rem',
  marginTop: '-0.15rem',
})

type ControlledTextFieldProps = {
  control: Control<any>
  name: string
  label: string
  tooltip?: string
  format: 'dollars' | 'number' | 'decimalPercent' | 'year'
  disabled?: boolean
  paddingTop?: string
}

const ControlledTextField = (props: ControlledTextFieldProps) => {
  const { control, name, label, format, tooltip, disabled, paddingTop } = props

  return (
    <Controller
      render={({ field, fieldState }) => {
        const { ref, ...otherFieldProps } = field
        return (
          <FormattedTextField
            size="small"
            format={format}
            label={
              <>
                {label}
                {tooltip && (
                  <Tooltip
                    title={tooltip}
                    arrow
                    placement="top"
                    enterTouchDelay={0}
                  >
                    <StyledHelp
                      sx={{
                        color: 'gray5.main',
                        marginBottom: '-2px',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </>
            }
            fullWidth
            sx={{ paddingTop }}
            disabled={disabled}
            error={!!fieldState.error}
            InputLabelProps={{
              shrink: true,
              style: { pointerEvents: 'auto' },
            }}
            {...otherFieldProps}
            helperText=""
            formatProps={{
              allowNegative: false,
            }}
            data-hj-allow
          />
        )
      }}
      name={name}
      rules={{ required: true }}
      control={control}
    />
  )
}

export default ControlledTextField

ControlledTextField.defaultProps = {
  disabled: false,
  tooltip: '',
  paddingTop: '',
}
