import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const MoneyFilledIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M32 4C16.5375 4 4 16.5375 4 32C4 47.4625 16.5375 60 32 60C47.4625 60 60 47.4625 60 32C60 16.5375 47.4625 4 32 4ZM33.3937 45.575L33.4062 47.5563C33.4062 47.8313 33.1812 48.0625 32.9062 48.0625H31.1313C30.8563 48.0625 30.6313 47.8375 30.6313 47.5625V45.6C25.0812 45.1875 22.4688 42.025 22.1875 38.5875C22.1625 38.2938 22.3937 38.0438 22.6875 38.0438H25.575C25.8188 38.0438 26.0312 38.2188 26.0688 38.4562C26.3875 40.4375 27.9313 41.9187 30.7 42.2875V33.3688L29.1562 32.975C25.8875 32.1937 22.775 30.1562 22.775 25.9312C22.775 21.375 26.2375 18.925 30.6625 18.4937V16.4312C30.6625 16.1562 30.8875 15.9312 31.1625 15.9312H32.9188C33.1938 15.9312 33.4188 16.1562 33.4188 16.4312V18.475C37.7 18.9062 40.9125 21.4062 41.2875 25.3C41.3187 25.5938 41.0875 25.85 40.7875 25.85H37.9813C37.7313 25.85 37.5187 25.6625 37.4875 25.4188C37.2375 23.5938 35.775 22.1063 33.3937 21.7812V30.175L34.9813 30.5438C39.0312 31.5438 41.7875 33.4812 41.7875 37.8187C41.7875 42.525 38.2875 45.15 33.3937 45.575ZM26.6625 25.6437C26.6625 27.2312 27.6438 28.4625 29.7563 29.225C30.05 29.3438 30.3438 29.4375 30.6938 29.5375V21.7875C28.3875 22.0813 26.6625 23.375 26.6625 25.6437ZM33.9437 34.0938C33.7687 34.0562 33.5938 34.0125 33.3937 33.9562V42.3125C36.0562 42.075 37.8937 40.6125 37.8937 38.1625C37.8937 36.2437 36.9 34.9937 33.9437 34.0938Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default MoneyFilledIcon
