import {
  DEFAULT_CLOSING_AND_LEGAL_FEE,
  DEFAULT_ONBOARDING_FEE,
} from '../businessConstants'

type CalculatorInput = {
  currentHouseValue: number
  monthlyRent: number
  purchasePrice: number
  dateOfPurchase: Date
  outstandingMortgageAmount: number
  onboardingFeeOverride: number
  closingAndLegalFeeOverride: number
  tenantTurnCost?: number
}

// eslint-disable-next-line import/prefer-default-export
export function sellVersusFlockCalculator(input: CalculatorInput) {
  const onboardingFee = input.onboardingFeeOverride || DEFAULT_ONBOARDING_FEE
  const closingAndLegalFee =
    input.closingAndLegalFeeOverride || DEFAULT_CLOSING_AND_LEGAL_FEE
  //
  // Hard-coded assumptions
  //
  const brokerFeePercentage = 0.06 // Standard 3% for buyer's broker, 3% for seller's broker (% of sale price)
  const closingAndLegalFees = closingAndLegalFee * input.currentHouseValue // One-time expense
  const stagingCosts = 1000 // One-time expense

  const daysOnMarket = 60
  const daysInYear = 365

  const propertyTaxPercentageOfEffectiveGrossRent = 0.08
  const insurancePercentageOfEffectiveGrossRent = 0.01
  const tenantTurnCost =
    input.tenantTurnCost !== undefined ? input.tenantTurnCost : 3500
  const assumedVacancyPercentage = 0.05

  const yearsLifetimeValueOfHouse = 27.5
  const buildingValuePercentageOfPurchasePrice = 0.8
  const depreciationRecaptureTaxRate = 0.25
  const capitalGainsTaxRate = 0.25

  //
  // Calculations for traditional sale
  //
  const brokerFees = input.currentHouseValue * brokerFeePercentage
  const salesCosts = Math.round(closingAndLegalFees + brokerFees)

  // Other costs
  const annualGrossRent = 12 * input.monthlyRent
  const annualEffectiveGrossRent =
    annualGrossRent -
    tenantTurnCost -
    annualGrossRent * assumedVacancyPercentage
  const nextYearOfPropertyTaxes =
    annualEffectiveGrossRent * propertyTaxPercentageOfEffectiveGrossRent
  const nextYearOfInsurance =
    annualEffectiveGrossRent * insurancePercentageOfEffectiveGrossRent
  const holdingCosts = Math.round(
    (daysOnMarket / daysInYear) *
      (nextYearOfPropertyTaxes + nextYearOfInsurance)
  )

  const otherCosts = holdingCosts + stagingCosts

  // Tax liability
  const buildingValueSubjectToDepreciation =
    input.purchasePrice * buildingValuePercentageOfPurchasePrice

  const today = new Date()
  const yearsSincePurchase =
    today.getFullYear() -
    input.dateOfPurchase.getFullYear() +
    (today.getMonth() - input.dateOfPurchase.getMonth()) / 12

  let depreciationClaimedToDate
  if (yearsSincePurchase < yearsLifetimeValueOfHouse) {
    // house is not fully depreciated
    depreciationClaimedToDate =
      (Math.floor(yearsSincePurchase) / yearsLifetimeValueOfHouse) *
      buildingValueSubjectToDepreciation
  } else {
    // house is fully depreciated
    depreciationClaimedToDate = buildingValueSubjectToDepreciation
  }

  const costBasisAtDateOfSale = input.purchasePrice - depreciationClaimedToDate

  const depreciationRecaptureTaxLiability = Math.round(
    depreciationClaimedToDate * depreciationRecaptureTaxRate
  )

  const valueSubjectToCapitalGains =
    input.currentHouseValue -
    salesCosts -
    otherCosts -
    costBasisAtDateOfSale -
    depreciationClaimedToDate
  const capitalGainsTaxLiability = Math.max(
    Math.round(valueSubjectToCapitalGains * capitalGainsTaxRate),
    0
  )

  const totalTaxLiability = Math.round(
    depreciationRecaptureTaxLiability + capitalGainsTaxLiability
  )

  // Opportunity costs
  const lostRent = Math.round(annualGrossRent * (daysOnMarket / daysInYear))

  // Net fees
  const netSellFees =
    salesCosts +
    otherCosts +
    totalTaxLiability +
    lostRent +
    input.outstandingMortgageAmount

  // Net value
  const traditionalSaleNetValue = input.currentHouseValue - netSellFees

  //
  // Calculations for Flock contribution
  //
  const flockOnboardingFee = input.currentHouseValue * onboardingFee

  const contributingCosts = Math.round(flockOnboardingFee + closingAndLegalFees)

  const netFlockFees = contributingCosts + input.outstandingMortgageAmount
  const flockNetValue = input.currentHouseValue - netFlockFees

  const output = {
    selling: {
      currentHouseValue: input.currentHouseValue,
      salesCosts,
      holdingCosts,
      stagingCosts,
      otherCosts,
      capitalGainsTaxLiability,
      depreciationRecaptureTaxLiability,
      taxLiability: totalTaxLiability,
      mortgagePayoff: input.outstandingMortgageAmount,
      lostRent,
      netValue: traditionalSaleNetValue,
      netFees: netSellFees,
    },
    contributingToFlock: {
      currentHouseValue: input.currentHouseValue,
      contributingCosts,
      mortgagePayoff: input.outstandingMortgageAmount,
      netValue: flockNetValue,
      netFees: netFlockFees,
    },
  }

  return output
}
